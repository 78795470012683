import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="accordion"
export default class extends Controller {
  static values = {
    openText: String,
    closeText: String,
  };

  toggle() {
    if (this.isOpen) {
      this.closeAccordion();
    } else {
      this.openAccordion();
    }
  }

  openAccordion() {
    this.element.setAttribute("aria-expanded", true);
    this.closeButton.classList.remove("hidden");
    this.openButton.classList.add("hidden");

    this.body.classList.add("visible");
    this.body.classList.remove("invisible");

    this.body.style.height = `${this.body.scrollHeight}px`;
    this.screenReaderText.innerHTML = this.closeTextValue;
    // In order to make nested accordions work, we need to reset the height
    const animationDuration = 200;
    setTimeout(() => {
      this.body.style.height = "";
    }, animationDuration);
  }

  closeAccordion() {
    this.element.setAttribute("aria-expanded", false);
    this.closeButton.classList.add("hidden");
    this.openButton.classList.remove("hidden");

    this.body.classList.remove("visible");
    this.body.classList.add("invisible");

    this.screenReaderText.innerHTML = this.openTextValue;
    this.body.style.height = this.body.scrollHeight + "px";

    setTimeout(() => {
      this.body.style.height = "0px";
    });
  }

  get id() {
    return this.element.getAttribute("data-accordion-id");
  }

  get isOpen() {
    return this.element.getAttribute("aria-expanded") === "true";
  }

  get openButton() {
    return this.findScopedElementById("open");
  }

  get closeButton() {
    return this.findScopedElementById("close");
  }

  get screenReaderText() {
    return this.findScopedElementById("sr");
  }

  get body() {
    return this.findScopedElementById("body");
  }

  findScopedElementById(scopeId) {
    return this.scope.findElement(
      `[data-accordion-${scopeId}-id="${this.id}"]`
    );
  }
}
