import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "minus",
    "plus",
    "input",
    "reset",
    "currency",
    "label",
    "error"
  ];

  static values = {
    initial: String,
    minPrice: Number,
    maxLimit: { type: Number, default: null },
    maxPrice: { type: Number, default: null }
  };

  initialize() {
    this.isDirty = false;
    this.previousValue = this.initialValue;
  }

  connect() {
    const currentValue = this.currentNumericValue();
    if (!this.plusTarget.disabled) this.updatePlusButton(currentValue);
    if (!this.minusTarget.disabled) this.updateMinusButton(currentValue);
  }

  minusAmount() {
    this.setInputValue(this.currentNumericValue() - 1);
  }

  plusAmount() {
    this.setInputValue(this.currentNumericValue() + 1);
  }

  resetAmount() {
    this.setInputValue(this.initialValue);
  }

  setInputValue(newValue) {
    this.inputTarget.value = newValue;
    this.previousValue = newValue;
    this.onInputChanged();
  }

  currentNumericValue() {
    return this.maxPriceValue && !this.isDirty
      ? this.minPriceValue
      : Number(this.currentInputValue()) || 0;
  }

  onInputChanged() {
    this.isDirty = this.currentInputValue() !== this.initialValue;
    const currentValue = this.currentNumericValue();

    this.updateResetLink();
    this.updateTextColor();
    this.validateInput(currentValue);
    this.updatePlusButton(currentValue);
    this.updateMinusButton(currentValue);
  }

  updateTextColor() {
    const greenText = Number(this.currentInputValue()) === this.minPriceValue;
    this.toggleClass(this.currencyTarget, "text-green", greenText);
    this.toggleClass(this.inputTarget, "!text-green", greenText);
  }

  validateInput(currentValue) {
    const isInvalid = this.maxLimitValue && currentValue > this.maxLimitValue;
    this.toggleClass(this.labelTarget, "!text-red", isInvalid);
    this.toggleClass(this.errorTarget, "hidden", !isInvalid);
  }

  updatePlusButton(currentValue) {
    const isMaxLimit = this.maxLimitValue && currentValue >= this.maxLimitValue;
    this.plusTarget.disabled = isMaxLimit;
    this.plusTarget.title = isMaxLimit ? this.errorTarget.innerHTML : "";
    this.toggleClass(this.plusTarget, "cursor-not-allowed", isMaxLimit);
  }

  updateMinusButton(currentValue) {
    const isMinLimit = currentValue <= 1;
    this.minusTarget.disabled = isMinLimit;
    this.toggleClass(this.minusTarget, "cursor-not-allowed", isMinLimit);
  }

  updateResetLink() {
    this.toggleClass(this.resetTarget, "hidden", !this.isDirty);
  }

  onChange() {
    const value = this.currentInputValue();
    let newValue = this.previousValue;

    if (!value) {
      newValue = "";
    } else if (!isNaN(value) && Number(value) > 0) {
      newValue = value;
    } else if (this.maxPriceValue && !this.isDirty) {
      newValue = this.minPriceValue;
    }
    this.setInputValue(newValue);
  }

  toggleClass(target, className, condition) {
    target.classList.toggle(className, condition);
  }

  currentInputValue() {
    return this.inputTarget.value;
  }
}
