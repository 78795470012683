import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";
import tippy from "tippy.js";

import { APIClient } from "../config/https";

export default class extends Controller {
  static values = {
    fetchEstimatedPayoutUrl: String,
    debounceInterval: { type: Number, default: 700 }
  };

  static targets = ["content", "price", "loading"];

  static debounces = ["initTippy", "onPriceChange"];

  connect() {
    useDebounce(this, { wait: this.debounceIntervalValue });
  }

  onPriceChange() {
    this.contentTarget.innerHTML = this.loadingTarget.innerHTML;

    return APIClient(
      `${this.fetchEstimatedPayoutUrlValue}&price=${this.priceTarget.value}`
    )
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
      .then(() => {
        this.initTippy();
      });
  }

  initTippy() {
    tippy("[data-tippy-hover]", { allowHTML: true });
  }
}
